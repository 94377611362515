.welcome-screen {
    display: flex;
    flex: auto;
    flex-flow: column nowrap;
    align-items: center;

    > .desc {
        align-self: stretch;
        margin-bottom: 4rem;
        padding: 0 2.4rem;
    }

    > .button {
        align-self: center;
        margin: auto;
    }
}
