.button {
    &.-primary {
        @include button($brand-purple, $white);
        @include font-weight(bold);
        @include elevation(1);

        min-width: 20rem;
        padding: 0.8em 2.4em;
        font-size: 1.8rem;
    }

    &.-text {
        @include button-reset();
    }

    &[disabled] {
        box-shadow: none;
        opacity: 0.2;
    }
}
