.report-screen {
    display: flex;
    flex: auto;
    flex-flow: column nowrap;
    align-items: center;

    > .print-heading {
        @include media('screen') {
            display: none;
        }

        @include media('print') {
            @include heading(600);

            display: block;
            margin: 4rem 0;
        }
    }

    > .overview {
        @include media('print') {
            display: none;
        }
        @include text(400);

        margin: 1.6rem 3.2rem;

        > .link {
            @include font-weight(bold);
            @include link-states () {
                color: $brand-cocoa;
            }
        }
    }

    > .instructions {
        @include media('print') {
            display: none;
        }
        @include font-weight(bold);

        margin: 1.6rem 1.6rem 0;
        font-style: italic;
        text-align: center;
    }

    > .results-section {
        margin: 2.4rem;
        padding: 0.8rem 2.4rem;
        background-color: $gray-300;
    }

    .results-item {
        width: 28rem;
        padding: 0.8rem 0;

        > .heading {
            @include heading(400);
            @include font-weight(normal);

            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
        }

        > .list {
            @include text(400);

            margin-top: 0.8rem;
            line-height: 2;
            list-style: disc inside;
        }

        > .list > .listitem {
            @include font-weight(bold);
        }
    }

    > .button {
        @include media('print') {
            display: none;
        }

        margin-bottom: 2.4rem;

        &.-text {
            @include text(300);
            @include font-weight(bold);

            @media (hover) {
                &:hover {
                    background-color: $hover-color;
                    outline: 0;
                }
            }

            padding: 1.6rem 3.2rem;

            .js-focus-visible &.focus-visible:focus,
            &:active {
                background-color: $brand-marigold;
                outline: 0;
            }

            > .icon {
                width: 1.2rem;
                height: 1.2rem;
                margin-right: 0.8rem;
            }
        }
    }

    > .resources {
        @include media('print') {
            display: none;
        }

        margin-bottom: 4rem;
        padding: 2.4rem 3.2rem 1.6rem;
        border-top: 1px solid $brand-cocoa;
        border-bottom: 1px solid $brand-cocoa;

        > .heading {
            @include heading(400);

            margin-bottom: 1.6rem;
        }

        > .list {
            @include text(400);

            list-style: disc inside;
        }

        > .list > .listitem {
            padding: 0.8rem;
        }

        > .list > .listitem > .link {
            @include link-states () {
                color: $brand-cocoa;
            }
        }
    }
}
