$font-url: 'https://fonts.googleapis.com/css?family=Nunito:300,400,700';

$font-family: 'Nunito', sans-serif;

$font-weights: (
    light: 300,
    normal: 400,
    bold: 700,
);

$default-font-size-text: 400;
$default-font-size-heading: 500;

$font-sizes: (
    100: 1rem,
    200: 1.2rem,
    300: 1.4rem,
    400: 1.6rem,
    500: 2rem,
    600: 2.4rem,
    700: 3.6rem,
);

$text-settings: (
    100: (
        color: map-get($font-colors, dark),
        font-weight: map-get($font-weights, normal),
        font-size: map-get($font-sizes, 100),
        line-height: 1.5,
    ),
    200: (
        color: map-get($font-colors, dark),
        font-weight: map-get($font-weights, normal),
        font-size: map-get($font-sizes, 200),
        line-height: 1.5,
    ),
    300: (
        color: map-get($font-colors, normal),
        font-weight: map-get($font-weights, normal),
        font-size: map-get($font-sizes, 300),
        line-height: 1.5,
    ),
    400: (
        color: map-get($font-colors, normal),
        font-weight: map-get($font-weights, normal),
        font-size: map-get($font-sizes, 400),
        line-height: 1.5,
    ),
    500: (
        color: map-get($font-colors, normal),
        font-weight: map-get($font-weights, normal),
        font-size: map-get($font-sizes, 500),
        line-height: 1.35,
    ),
);

$heading-settings: (
    100: (
        color: map-get($font-colors, normal),
        font-weight: map-get($font-weights, bold),
        font-size: map-get($font-sizes, 100),
        line-height: 1.2,
        text-transform: uppercase,
    ),
    200: (
        color: map-get($font-colors, normal),
        font-weight: map-get($font-weights, bold),
        font-size: map-get($font-sizes, 200),
        line-height: 1.2,
    ),
    300: (
        color: map-get($font-colors, normal),
        font-weight: map-get($font-weights, bold),
        font-size: map-get($font-sizes, 300),
        line-height: 1.2,
    ),
    400: (
        color: map-get($font-colors, normal),
        font-weight: map-get($font-weights, bold),
        font-size: map-get($font-sizes, 400),
        line-height: 1.2,
    ),
    500: (
        color: map-get($font-colors, normal),
        font-weight: map-get($font-weights, bold),
        font-size: map-get($font-sizes, 500),
        line-height: 1.2,
    ),
    600: (
        color: map-get($font-colors, normal),
        font-weight: map-get($font-weights, bold),
        font-size: map-get($font-sizes, 600),
        line-height: 1.2,
    ),
    700: (
        color: map-get($font-colors, dark),
        font-weight: map-get($font-weights, light),
        font-size: map-get($font-sizes, 700),
        line-height: 1.2,
    ),
);
