/* stylelint-disable selector-max-type */
button {
    margin: 0;
    padding: 0;
    color: inherit;
    font: inherit;
    text-align: inherit;
    background: none;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    appearance: none;
}
/* stylelint-enable selector-max-type */
