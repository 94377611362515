.progress {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: -1.6rem;
    font-size: 1.8rem;

    > .page {
        margin: 0 0.8rem;
        color: $black;
        opacity: 0.3;

        &.-on {
            opacity: 1;
        }
    }
}
