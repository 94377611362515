.page-header {
    @include media('print') {
        display: none;
    }

    align-self: stretch;
    padding: 3.2rem 0 2.4rem;
    color: $black;
    text-align: center;

    &.-donezo {
        color: $white;
        background-color: $brand-purple;
    }

    > .heading {
        @include heading(600);

        padding-bottom: 0.8rem;
        color: inherit;
    }
}
