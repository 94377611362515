.symptoms-screen {
    display: flex;
    flex: auto;
    flex-flow: column nowrap;
    align-items: center;

    > .symptoms-question {
        @include text(400);
        @include font-weight(bold);

        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        width: 40rem;
        max-width: 100%;
        margin-top: 3.2rem;
        padding: 0 4rem;
    }

    > .symptoms-question > .question {
        width: 100%;
        margin-bottom: 2.4rem;
    }

    > .symptoms-question > .answers {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
    }

    > .symptoms-question > .answers > .button {
        @include button-reset();

        @media (hover) {
            &:hover {
                background-color: $hover-color;
                outline: 0;
            }
        }

        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 45%;
        padding-right: 3.2rem;
        line-height: 3;
        border: 2px solid $hover-color;
        border-radius: 1px;
        cursor: pointer;

        &.-on,
        .js-focus-visible &.focus-visible:focus,
        &:active {
            background-color: $brand-marigold;
            outline: 0;
        }

        &:first-of-type {
            margin-right: 10%;
        }
    }

    > .symptoms-question > .answers > .button > .icon {
        flex: none;
        margin: 0 2.1rem;
    }

    > .symptoms-question > .answers > .button:not(.-on) > .icon {
        @include invisible();
    }

    > .symptoms-question > .answers > .figure {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        margin: 1.6rem 0;
    }

    > .symptoms-question > .answers > .figure > .caption {
        @include text(200);

        margin-top: 0.8rem;
        font-style: italic;
        text-align: center;
    }
}
