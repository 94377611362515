$white: #fff;
$gray-50: #fafafa;
$gray-100: #f5f5f5;
$gray-200: #eee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;
$black: #000;

$font-colors: (
    light: $gray-600,
    normal: $gray-800,
    dark: $black,
);

$brand-blue: #7ed9e8;
$brand-marigold: #faa31e;
$brand-cocoa: #6e281e;
$brand-cherry: #ef453d;
$brand-hazelnut: #c67d30;
$brand-purple: #5c068c;

$hover-color: rgba($brand-marigold, 0.2);
