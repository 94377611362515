.extent-screen {
    display: flex;
    flex: auto;
    flex-flow: column nowrap;
    align-items: center;

    > .button {
        margin: auto;
    }

    > .extent-form {
        width: 26rem;
    }

    > .extent-form > .button {
        @include button-reset();
        @include text(400);

        @media (hover) {
            &:hover {
                background-color: $hover-color;
                outline: 0;
            }
        }

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        width: 100%;
        margin: 0.8rem 0;
        padding-right: 2.4rem;
        line-height: 3.2;
        white-space: nowrap;
        border: 2px solid $hover-color;
        cursor: pointer;

        &.-on {
            @include font-weight(bold);
        }

        &.-on,
        .js-focus-visible &.focus-visible:focus,
        &:active {
            background-color: $brand-marigold;
            outline: 0;
        }
    }

    > .extent-form > .button > .icon {
        flex: none;
        margin: 0 2.4rem;
    }

    > .extent-form > .button:not(.-on) > .icon {
        @include invisible();
    }
}
