.footer {
    @include text(300);

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-top: auto;
    padding: 1.6rem;

    > .link {
        margin-top: 0.8rem;

        &:focus {
            outline: 2px solid $brand-marigold;
        }
    }
}
