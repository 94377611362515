@import url($font-url);

/* stylelint-disable selector-max-type, selector-max-id */
*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    width: 100%;
    color: $black;
    font-size: 62.5%;
}

body {
    @include media('print') {
        background-color: initial;
    }

    @include text();

    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    min-height: 100%;
    background-color: $brand-marigold;
}

input,
textarea,
select {
    font-family: inherit;
}

strong {
    font-weight: font-weight(bold);
}

#root {
    @include media('>600px') {
        @include elevation(2);

        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
    }
    @include media('print') {
        box-shadow: none;
    }

    display: flex;
    flex: auto;
    flex-flow: column nowrap;
    align-items: stretch;
    width: 100%;
    max-width: 60rem;
    height: 100%;
    background-color: $white;
}
/* stylelint-enable selector-max-type, selector-max-id */
