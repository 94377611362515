.intensity-screen {
    display: flex;
    flex: auto;
    flex-flow: column nowrap;
    align-items: center;

    > .intensity-form > .button {
        @include button-reset();
        @include text(400);
        @include font-weight(bold);

        @media (hover) {
            &:hover {
                background-color: $hover-color;
                outline: 0;
            }
        }

        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        min-height: 6.4rem;
        margin: 1.6rem 0;
        padding: 0.8rem 4.8rem;
        white-space: nowrap;
        border: 2px solid $hover-color;
        cursor: pointer;

        &.-noimage {
            justify-content: center;
        }

        &:focus,
        &:active {
            background-color: $brand-marigold;
            outline: 0;
        }
    }

    > .intensity-form > .button > .image {
        margin-right: 3.2rem;
    }
}
