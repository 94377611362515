/* stylelint-disable selector-max-type */
a:link,
a:visited,
a:focus,
a:hover,
a:active {
    color: inherit;
    text-decoration: none;
}
/* stylelint-enable selector-max-type */
